<template>
  <el-container>
  <!-- <el-header class="header">
       “AIOT+区块链”茶产业技术示范与应用
  </el-header> -->
  <el-main class="main_sym">
      <div class="title">追溯码查询</div>
      <div class="input"> 
          <input type="text" placeholder="请输入追溯码" class="textfi"><button class="btn">查询</button>
      </div>
  </el-main>
   <el-footer class="footer">
       <el-row type="flex" class="row-bg" justify="center">
  <el-col :span="6" style="line-height:29px">
      <div>快速入口</div>
      
      <div>
<el-link type="info" href="https://www.ahjinzhai.gov.cn/" >金寨县人民政府</el-link>
      </div>
      <div>
 <el-link type="info" href="http://nync.ah.gov.cn/">安徽省农业农村厅</el-link>
      </div>
      <div>
<el-link type="info" href="http://www.aielab.net/ahsy">安徽省农产品追溯平台</el-link>
      </div>
        
          
           
  </el-col>
  <el-col :span="6"><div class="grid-content bg-purple-light"></div></el-col>
  <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
</el-row>
<el-row type="flex" class="row-bg" justify="center">
  <el-col :span="6">
      
  </el-col>
  <el-col :span="6"><div class="grid-content bg-purple-light"></div></el-col>
  <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
</el-row>
<div style="color: #909399;">安徽椒图信息有限公司</div>
   </el-footer>
</el-container>
</template>

<script>
export default {


}
</script>

<style>
.header{
    font-weight: 700;
    font-size: 2.0vw;
    line-height: 4rem;
    font-family: Microsoft YaHei;
    color: #2c3e50;
    min-height: 5vw;
}
.main_sym{
    background-image: url("../assets/images/selectsym2.png");
    background-size: cover;
    min-height: 25vw;
    margin-bottom: 10vw;
}
.title{
    color: snow;
    text-align: center;
    font-size: 2.8vw;
}
.input{
    text-align: center;
    margin-top: 3vw;

}
.textfi{
    background:rgba(255, 255, 255, 0.75);
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 25vw;
    height: 2.7vw;
    padding-left: 5px;
}
.btn{
    box-sizing: border-box;
    /* padding: 14px 34px; */
    font-size: 1vw;
    color: #fff;
   
    width: 6vw;
    height: 3vw;
    background: #44d981;
    border-radius: 8px;
    cursor: pointer;
}
.footer{
    background-image: url("../assets/images/footer.png");
    background-size: cover;
    height: 200px!important;
    color: seashell;
    padding-top: 30px;
    text-align: center;
}
</style>